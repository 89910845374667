module.exports = exports = {
  ERRORS: {
    UNAUTHORIZED: {
      code: 1001,
      message: 'Érvénytelen felhasználónév / jelszó'
    },
    FORBIDDEN: {
      code: 1002,
      message: 'Hozzáférés nem engedélyezett'
    },
    VALIDATION: {
      code: 1101,
      message: 'Érvénytelen bemeneti adatok'
    }
  },
  EFFECTIVESTATUS: {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    DELETED: 'DELETED',
    PENDING_REVIEW: 'PENDING_REVIEW',
    IN_PROCESS: 'IN_PROCESS',
    WITH_ISSUES: 'WITH_ISSUES',
    DISAPPROVED: 'DISAPPROVED',
    PREAPPROVED: 'PREAPPROVED',
    PENDING_BILLING_INFO: 'PENDING_BILLING_INFO',
    CAMPAIGN_PAUSED: 'CAMPAIGN_PAUSED',
    ARCHIVED: 'ARCHIVED',
    ADSET_PAUSED: 'ADSET_PAUSED',
    RECENTLY_COMPLETED: 'RECENTLY_COMPLETED'
  },
  EFFECTIVESTATUSTITLES: {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    DELETED: 'DELETED',
    PENDING_REVIEW: 'PENDING_REVIEW',
    IN_PROCESS: 'IN_PROCESS',
    WITH_ISSUES: 'WITH_ISSUES',
    DISAPPROVED: 'DISAPPROVED',
    PREAPPROVED: 'PREAPPROVED',
    PENDING_BILLING_INFO: 'PENDING_BILLING_INFO',
    CAMPAIGN_PAUSED: 'CAMPAIGN_PAUSED',
    ARCHIVED: 'ARCHIVED',
    ADSET_PAUSED: 'ADSET_PAUSED',
    RECENTLY_COMPLETED: 'COMPLETED'
  },
  ROLE: {
    ADMIN: 'admin'
  },
  CAMPAIGNSOURCE: {
    FEED: 'feed',
    FILE: 'file',
    PAGEPOSTS: 'pageposts'
  },
  MARKETING_GOALS: {
    LINK_CLICKS: 'LINK_CLICKS',
    PAGE_LIKES: 'PAGE_LIKES',
    POST_ENGAGEMENT: 'POST_ENGAGEMENT',
    REACH: 'REACH',
    CONVERSIONS: 'CONVERSIONS',
    LEAD_GENERATION: 'LEAD_GENERATION',
    OUTCOME_TRAFFIC: 'OUTCOME_TRAFFIC',
    OUTCOME_SALES: 'OUTCOME_SALES'
  }
}
